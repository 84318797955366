<template>
  <div id="signupwindow">
    <div class="mask" v-if="showQuestionBox" @click="close()"/>
    <div class="pageOne" v-if="showOne">
      <email-check-item v-model="sendCodeForm.email" v-on:emailOk="checkEmailOk"/>

      <div class="verificationBox">
        <div class="formBox">
          <div class="text">{{$t(`lang.verificationCode`)}}</div>
          <input type="text" :placeholder="$t(`lang.CheckEmail`)" v-model="registerUserForm.code" maxlength="6" @blur="checkCode()" >
          <span class="errorMessage">{{codeMessage}}</span>
        </div>
        <div class="sendbox">
          <btn-submit class="btnLogin" :height="height" :color="btnColor" :disable='disable' @click="sendCode()">{{getCode}}</btn-submit>
        </div>
      </div>
      <div class="formBox">
        <div class="questionBox" v-if="showQuestionBox">
          <p>You can get Broker number from your broker</p>
          <div class="btnClose" @click="close">
            <svg-icon name="wrong"></svg-icon>
          </div>
        </div>
        <div class="verificationBox">
          <div class="text">Broker</div><div class="question" @click="showQuestion()">?</div>
        </div>
        
        <input type="text" :placeholder="$t(`lang.enterBroker`)" v-model="registerUserForm.broker" maxlength="6">
        <span class="errorMessage">{{message}}</span>
      </div>
      <btn-submit class="btnLogin" :width="width" :height="height" :color="btnColor" @click="goNext()">Next</btn-submit>
    </div>

    <div class="pageTwo" v-if="showTwo">
      <password-check-item v-model="registerUserForm.password" :checkBox="true" v-on:emailOk="checkEmailOk"/>
      <password-check-item v-model="confirmPassword" title="Confirm Password" placeholder="Enter Your Password Again" />
      <btn-submit class="btnLogin" :width="width" :height="height" :color="btnColor" @click="signUp()">Sign Up</btn-submit>
    </div>
  </div>
</template>

<script>
import BtnSubmit from '@/components/button/BtnSubmit.vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'

import { sendCodeMail,registerUser } from '@/axios/api/login'
import EmailCheckItem from '@/components/form/EmailCheckItem.vue'
import PasswordCheckItem from '@/components/form/PasswordCheckItem.vue'

export default {
  name:'SignUp',
  components: {
    BtnSubmit,
    SvgIcon,
    EmailCheckItem,
    PasswordCheckItem,

  },
  data() {
    return{
      confirmPassword:'',
      checkEmailCode:false,
      passOK:false,
      emailOK:false,
      formCheck:false,
      codeMessage:'',
      message:'',
      btnColor:'red',
      width:400,
      height:50,
      count:60,
      disable:false,
      isGeting:false,
      getCode:'Get Code',
      showOne:true,
      showTwo:false,
      showQuestionBox:false,
      sendCodeForm:{
        email:'',
      },
      registerUserForm:{
        broker: "",
        code: "",
        email: "",
        password: ""
      }

    }
  },
  methods:{
    sendCode(){
      if(this.emailOK){
        this.disable = true;
        sendCodeMail(this.sendCodeForm)
        var countDown = setInterval(()=>{
          if(this.count < 1){
            this.disable = false;
            this.isGeting = false;
            this.getCode = 'Send';
            this.count = 60;
            clearInterval(countDown);
          }else{
            this.disable = true;
            this.isGeting = true;
            this.getCode = this.count-- + 's Resend';
          }
        },1000);
      } else {
        alert("Please Enter Email First")
      }
    },
    goNext(){
      if(this.checkEmailCode&&this.emailOK){
        this.showOne=false
        this.showTwo=true
      }else{
        alert("请先填写表单")
      }
    },
    showQuestion(){
      this.showQuestionBox=!this.showQuestionBox
    },
    close(){
      this.showQuestionBox=false
    },
    signUp(){
      if(this.registerUserForm.password==this.confirmPassword){
        this.passOK=true
      }else {
        this.passOK=false
      }
      if(this.passOK==this.emailOK==true){
        registerUser(this.registerUserForm).then(res=>{
          if(res.code===20000){
            window.location.href = '/usercenter'
            console.log("注册成功")
          } else {
            console.log("添加失败")
          }
        })
      } else {
        alert("表格不正确")
      }

    },
    checkCode(){
      const code = /^[0-9]{6}$/;
      if(this.registerUserForm.code==null) {
        this.codeMessage = "不能为空"
      } else if(!code.test(this.registerUserForm.code)){
        this.codeMessage = "格式不正确"
      } else if(code.test(this.registerUserForm.code)){
        this.codeMessage = ''
        this.checkEmailCode=true
      } else {
        this.codeMessage = ''
      }
    },
    checkEmailOk(){
      this.emailOK=true
    },

  },
  watch:{
    "sendCodeForm.email":function(){
      this.registerUserForm.email = this.sendCodeForm.email
    },
  }
}
</script>

<style scoped>
input{
  outline: none;
  width: 100%;
  border: none;
  background: none;
  height: 40px;
  font-size: 18px;
}
.text{
  font-size: 24px;
}
.formBox{
  position: relative;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
  box-sizing: border-box;
  min-width: 300px;
}
.errorMessage{
  position: absolute;
  bottom: -26px;
  right: 20px;
}
.pageOne{
  width: 100%;
}
.verificationBox{
  display: flex;
  width: 100%;
  align-items: center;
}
.sendbox{
  width: 40%;
  margin-left: 60px;
  box-sizing: border-box;
}
.question{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #212121;
  color: aliceblue;
  font-size: 20px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  transition: .4s;
}
.question:hover{
  background: #5c5c5c;
}
.questionBox{
  position: absolute;
  width: 300px;
  height: 50px;
  background: #212121;
  top: -50px;
  left: 120px;
  border: 2px solid #8192ca;
  border-radius: 20px;
  color: aliceblue;
  padding: 8px;
  font-size: 14px;
  z-index: 9;
}
.btnClose{
  position: absolute;
  bottom: -10px;
  right: -10px;
  cursor: pointer;
}
.mask{
  width: 100vw;
  height: 100vh;
  z-index: 8;
  position: absolute;
  top: 0;
  left: 0;
}

</style>
