<template>
<div class="body">
  <div id="loginView">
    <div class="left">
      <div class="backBox">
        <btn-back class="btnBack"></btn-back>
      </div>
      <div class="loginTextBox" v-if="loginText">
        <div class="loginTextContent">
          <div class="line1Text">
            {{$t(`lang.hellothere`)}}
          </div>
          <h1>{{$t(`lang.welcomeBack`)}}</h1>
          <h2>{{$t(`lang.loginText1`)}}</h2>
          <div class="userCenter">
            <div class="point"></div>
            <h3>{{$t(`lang.loginText2`)}}</h3>
            <h3 class="goUserCenter">User Center</h3>
          </div>
          <div class="userCenter">
            <div class="point"></div>
            <h3>{{$t(`lang.loginText3`)}}</h3>
            <div class="btn">
              <btn-submit :color="btnColor" @click="goSignUp()">{{$t(`lang.signUp`)}}</btn-submit>
            </div>
          </div>
          <div class="downloadBox">
            <h2 class="downloadText">{{$t(`lang.loginText4`)}}</h2>
            <svg-icon name="google" size=180></svg-icon>
            <svg-icon class="isoBox" name="iso" size=180></svg-icon>
          </div>
        </div>
      </div>

      <div class="loginTextBox" v-if="signUpText">
        <div class="loginTextContent">
          <h1 class="signUp">{{$t(`lang.signUpText1`)}}</h1>
          <h2>{{$t(`lang.signUpText2`)}}</h2>
          <div class="userCenter">
            <div class="point"></div>
            <h3>{{$t(`lang.loginText2`)}}</h3>
            <h3 class="goUserCenter">{{$t(`lang.userCenter`)}}</h3>
          </div>
          <div class="userCenter">
            <div class="point"></div>
            <h3>{{$t(`lang.loginText3`)}}</h3>
            <div class="btn">
              <btn-submit @click="goSignIn">{{$t(`lang.signIn`)}}</btn-submit>
            </div>
          </div>
          <div class="downloadBox">
            <h2 class="downloadText">{{$t(`lang.loginText4`)}}</h2>
            <svg-icon name="google" size=180></svg-icon>
            <svg-icon class="isoBox" name="iso" size=180></svg-icon>
          </div>
        </div>
      </div>

      <div id="wave">
        <div class="wavebackground">
          <div class="waveBack" id="waveBack" :class="{waveMove:isWave,loginWaveMove:loginWave}" :style="{background:waveBoxColor}"></div>
          <div class="wave">
            <div class="wave3">
              <svg width="344" height="960" viewBox="0 0 344 960" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M83.0754 1661L111.249 1597C138.7 1533 194.324 1405 200.826 1277C208.05 1149 166.151 1021 194.324 893C221.775 765 319.298 637 339.525 509C360.475 381 304.85 253 235.5 125C166.151 -2.99999 83.0753 -131 41.8989 -195L-1.09611e-05 -259V-259L-8.56122e-06 -195C-6.16134e-06 -131 -1.36159e-06 -2.99998 3.43816e-06 125C8.23791e-06 253 1.30377e-05 381 1.78374e-05 509C2.26372e-05 637 2.74369e-05 765 3.22367e-05 893C3.70364e-05 1021 4.18362e-05 1149 4.66359e-05 1277C5.14357e-05 1405 5.62354e-05 1533 5.86353e-05 1597L6.10352e-05 1661L83.0754 1661Z" 
                  :fill="wave3color"/>
              </svg>
            </div>
            <div class="wave2">
              <svg width="255" height="960" viewBox="0 0 255 960" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M82.8806 -451L108.87 -411.833C135.316 -372.667 187.751 -294.333 174.528 -216C161.761 -137.667 82.8806 -59.3333 47.7719 19C12.6632 97.3333 21.3264 175.667 43.2123 254C65.0983 332.333 100.207 410.667 135.316 489C170.424 567.333 205.533 645.667 227.419 724C249.305 802.333 257.968 880.667 244.745 959C231.522 1037.33 196.414 1115.67 165.865 1194C135.316 1272.33 108.87 1350.67 96.1033 1389.83L82.8805 1429L4.00001 1429L4.00001 1389.83C4.00001 1350.67 4.00001 1272.33 4.00001 1194C4.00002 1115.67 4.00002 1037.33 4.00002 959C4.00003 880.667 4.00003 802.333 4.00003 724C4.00004 645.667 4.00004 567.333 4.00004 489C4.00004 410.667 4.00005 332.333 4.00005 254C4.00005 175.667 4.00006 97.3333 4.00006 19C4.00006 -59.3333 4.00006 -137.667 4.00007 -216C4.00007 -294.333 4.00007 -372.667 4.00007 -411.833L4.00008 -451L82.8806 -451Z" 
                  :fill="wave2color"/>
                <defs>
                <filter id="filter0_d" x="0" y="-451" width="255" height="1888" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                </defs>
              </svg>
            </div>
            <div class="wave1">
              <svg width="326" height="1256" viewBox="0 0 326 1256" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M263.499 12C263.499 12 263.499 31 279.728 66.5C295.956 102 309.226 186 297.585 293C285.945 400 204.463 552 163.47 623C122.476 694 84.8507 828.5 95.1463 930.5C104.927 1032.5 148.257 1142 178.115 1193L207.457 1244L7.18273e-06 1244L9.41201e-06 1193C1.16413e-05 1142 1.60998e-05 1040 2.05584e-05 938C2.5017e-05 836 2.94755e-05 734 3.39341e-05 632C3.83927e-05 530 4.28512e-05 428 4.73098e-05 326C5.17683e-05 224 5.62269e-05 122 5.84562e-05 71L6.10352e-05 12L263.499 12Z" 
                  :fill="wave1color"/>
                  </g>
                  <defs>
                  <filter id="filter0_d" x="0" y="0" width="326" height="1256" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset dx="12"/>
                  <feGaussianBlur stdDeviation="6"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="right">
      <div class="line1">
        <div class="logoBox" :style="{background: waveBoxColor}">
          <svg class="logo" data-name="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.15 115.38">
            <path fill="#FFF" d="M-240.5,104h-11.67q0-9-4.14-13.31a13.4,13.4,0,0,0-10.09-4.35,13.35,13.35,0,0,0-10,4.29A14.38,14.38,0,0,0-280.51,101v19.6h17v12.1h-17v19.36h-11.66V101.14a26.38,26.38,0,0,1,7.58-19,24.38,24.38,0,0,1,18.2-7.86,24.21,24.21,0,0,1,18.43,8Q-240.5,90.25-240.5,104Z" transform="translate(292.17 -74.28)"/>
            <path fill="#FFF" d="M-176,159.78q0,13.91-7.5,21.78a26,26,0,0,1-19.24,8.1,25.9,25.9,0,0,1-19-7.86,25.9,25.9,0,0,1-7.86-19V74.28h12.1v56.95h17.79v12.1h-17.8v19.48a14.13,14.13,0,0,0,4.36,10.47,14.29,14.29,0,0,0,10.41,4.3,14,14,0,0,0,10.41-4.36q4.23-4.36,4.23-13.43H-176Z" transform="translate(292.17 -74.28)"/>
            <path fill="#FFF" d="M-256.27,109.15V173.1h-11.85a12.25,12.25,0,0,1-12.23-12.23v-15h-11.82v19.74a24.08,24.08,0,0,0,24.08,24.08h27V109.14h-15.18Z" transform="translate(292.17 -74.28)"/>
            <path fill="#FFF" d="M-188.58,100.47v52.82h11.79V98.37a24.08,24.08,0,0,0-24.08-24.08h-7V86.24h7C-193,86.24-188.58,92.63-188.58,100.47Z" transform="translate(292.17 -74.28)"/>
          </svg>
        </div>
        <div class="logotext">FINGER TRADING</div>
      </div>
      <div class="loginform" v-if="loginText">
        <login-window @isShow="isShowValue"></login-window>
        <div class="signUpLine"><span>{{$t(`lang.dontAccount`)}}</span><a class="signUpText" @click="goSignUp()">Sign Up</a></div>
      </div>

      <div class="loginform" v-if="signUpText">
        <sign-up-window></sign-up-window>
        <div class="signUpLine"><span>{{$t(`lang.alreadyAccount`)}}</span><a class="signUpText" @click="goSignIn()">Sign In</a></div>
      </div>
    </div>

  </div>
</div>
  <Loading v-if="isShow"></Loading>

</template>

<script>
import BtnBack from '@/components/button/BtnBack.vue'
import LoginWindow from '../components/LoginWindow.vue'
import BtnSubmit from '@/components/button/BtnSubmit.vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
import SignUpWindow from '../components/SignUpWindow.vue'
import Loading from '@/components/loading/Loading'
export default {
  name:'Login',
  components: {
    LoginWindow,
    BtnBack,
    BtnSubmit,
    SvgIcon,
    SignUpWindow,
    Loading,
  },
  data() {
    return {
      wave3color:"#0F87CB",
      wave2color:"#0C9AEA",
      wave1color:"#0099FF",
      waveBoxColor:"#0099FF",
      isWave:false,
      loginWave:false,
      btnColor:"red",
      loginText:true,
      signUpText:false,

      isShow:false,
    }
  },
  methods:{
    goSignUp(){
      document.querySelector(".waveBack").className = "waveBack";
      this.isWave= true
      this.loginWave=false
      this.signUpText=true
      this.loginText=false
      this.wave3color = "#CB0F47"
      this.wave2color = "#EA0C4E"
      this.wave1color = "#FF1057"
      this.waveBoxColor = "#FF1057"
      
    },
    goSignIn(){
      document.querySelector(".waveBack").className = "waveBack";
      this.loginWave=true
      this.isWave= false
      this.signUpText=false
      this.loginText=true
      this.wave3color = "#0F87CB"
      this.wave2color = "#0C9AEA"
      this.wave1color = "#0099FF"
      this.waveBoxColor = "#0099FF"
    },
    isShowValue(item){
      this.isShow = item
    }
  },
}
</script>

<style scoped>
.body{
  background: #000;
  padding-top: 50px;
}
#loginView{
  position: relative;
  width: 100vw;
  height: 100%;
  background: #f9f9f9;
  display: flex;
  overflow: hidden;
}
.left{
  position: relative;
  left: 0;
  width: 50vw;
  height: 100%;
}
.right{
  position: relative;
  right: 0;
  height: 100vh;
  width: 50vw;
  padding-top: 40px;
}
.signUp{
  font-size: 50px;
  margin-bottom: 40px;
}
.logoBox{
  display: flex;
  justify-content: center;
  background: #0099FF;
  width: 100px;
  height: 100px;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 20px auto;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
}
.logo{
  height: 60px;
}
.logotext{
  display: flex;
  font-size: 40px;
  justify-content: center;
  font-weight: 900;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.line1{
  margin-bottom: 20px;
}
.loginform{
  height: 500px;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}
.loginTextContent{
  position: absolute;
  z-index: 9;
  color: #fff;
  top: 10%;
  left: 16%;
  text-align: start;
  width: 576px;
  transition: .6s;
}
.backBox{
  position: absolute;
  z-index: 9;
}

h1,.line1Text{
  font-size: 64px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: start;
}
h2,h3{
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.point{
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  margin-top: 4px;
  margin-right: 16px;
}
.btn{
  margin-top: -6px;
  margin-left: 30px;
}
.userCenter{
  position: relative;
  width: 100%;
  margin-top: 40px;
  text-align: center;
  display: flex;

}
.goUserCenter{
  color: blue;
  cursor: pointer;
  margin-left: 20px;
  transition: .4s;
}
.goUserCenter:hover{
  color: rgba(3, 3, 141, 0.795);
}
.signUpLine{
  display: flex;
  justify-content: center;
}
.signUpText{
  color: blue;
  cursor: pointer;
  margin-left: 20px;
  font-weight: 900;
  width: 100px;
  height: 20px;
}
.isoBox{
  margin-left: 20px;
}
.downloadText{
  position: absolute;
  
}
.downloadBox{
  position: relative;
  margin-top: 100px;
}
#wave{
  display: flex;
  height: 100vh;
}
.wavebackground{
  display: flex;
  height: 100%;
}
.waveBack{
  background: #0099FF;
  height: 100vh;
  width: 621px;
  z-index: 2;
}
.wave{
  position: relative;
  height: 100%;
}
.wave1{
  position: absolute;
  left: 0;
  z-index: 3;
}
.wave1 svg{
  height: 100%;
  margin-top: -60px;
  z-index: 9;
}
.wave2{
  position: absolute;
  left: 0px;
  z-index: 2;
}
.wave3{
  position: absolute;
  left: -60px;
  z-index: 1;
}
.waveMove{
  animation: toleft 1s ease-in-out;
}
.loginWaveMove{
  animation: toleft 1s ease-in-out;
}
svg{
  transition: .6s;
}
@keyframes toleft {
  0%{
    width: 620px;
  }
  50%{
    width: 100vw;
  }
  100%{
    width: 621px;
  }
}
@media screen and (max-width: 1600px){
.waveBack{
  background: #0099FF;
  height: 100vh;
  width: 421px;
  z-index: 2;
  }
}
@media screen and (max-width: 1200px){
.waveBack{
  background: #0099FF;
  height: 100vh;
  width: 321px;
  z-index: 2;
  }
}
@media screen and (max-width: 1050px){
  .waveBack{
    height: 80px;
    width: 100vw;
  }
  #wave{
    width: 100vw;
    position: absolute;
    height: 100vh;
  }
  .wave1 svg,.wave2 svg,.wave3 svg{
    transform: rotate(90deg);
    height: 100vh;
    width: 100vw;
  }
  .wave,.wave1,.wave2,.wave3 {
    width: 100vw;
    margin-top: -160px;
    height: 100vh;
  }
  .wavebackground{
    display: block;
    height: 500px;
    width: 100vw;
  }
  #loginView{
    position: relative;
    width: 100vw;
    height: 100vh;
    background: #f9f9f9;
    display: block;
  }
  h1{
    font-size: 26px;
  }
  .loginTextContent{
    position: absolute;
    text-align: start;
    width: 100vw;
    transition: .6s;
    height: 260px;
    top: 20px;
    left: 8%;
  }
  .backBox{
    display: none;
  }
  h3{
    font-size: 16px;
  }
  .line1Text{
    display: none;
  }
  .userCenter{
    margin-top: 20px;
  }
  .btnBox{
    margin-top: 20px;
  }
  .downloadBox{
    display: none;
  }
  .left, .right{
    width: 100vw;
  }
  .left{
    height: 28%;
  }
  .logoBox{
    display: none;
  }
  .logotext{
    display: none;
  }
  .right{
    position: absolute;
    z-index: 999;
  }
  .loginform{
    margin-left: 0%;
    width: 100%;
    box-sizing: border-box;
  }
}
</style>
