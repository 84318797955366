<template>
  <div id="loginwindow">
    <div class="form">
      <div class="login">
        <email-check-item v-model='loginForm.email'/>
        <password-check-item v-model='loginForm.password'/>
        <div class="loginline">
          <input class="checkbox" type="checkbox">
          <span>{{$t(`lang.RememberMe`)}}</span>
          <a @click="findPassword()">{{$t(`lang.forgotPasswoed`)}}</a>
        </div>
        <btn-submit class="btnLogin" :width="width" :height="height" @click="signIn()">{{$t(`lang.login`)}}</btn-submit>
      </div>
    </div>
  </div>

    <div id="loginwindowPhone">
    <div class="form">
      <div class="login">
        <email-check-item v-model='loginForm.email'/>
        <password-check-item v-model='loginForm.password'/>
        <div class="loginline">
          <input class="checkbox" type="checkbox">
          <span>{{$t(`lang.RememberMe`)}}</span>
          <a @click="findPassword()">{{$t(`lang.forgotPasswoed`)}}</a>
        </div>
        <btn-submit class="btnLogin" :width="phoneheight" :height="height" @click="signIn()">{{$t(`lang.login`)}}</btn-submit>
      </div>
    </div>
  </div>
</template>

<script>
import BtnSubmit from '@/components/button/BtnSubmit.vue'
import EmailCheckItem from '@/components/form/EmailCheckItem.vue'
import PasswordCheckItem from '@/components/form/PasswordCheckItem.vue'

import cookie from 'js-cookie'
import { loginUser } from '@/axios/api/login'

export default {
  name:'LoginWindow',
  components: {
    BtnSubmit,
    EmailCheckItem,
    PasswordCheckItem,
  },
  data() {
    return {
      width: 400,
      height: 50,
      phoneheight: 300,
      message: '',
      loginForm: {
        email:'',
        password:''
      },
      isShow:false
    }
  },
  methods:{
    signIn(){
      loginUser(this.loginForm).then(res=>{
        if(res.code===20000){
          //将 token 写入 cookie
          cookie.set('userInfo',res.data.userInfo)
          window.location.href = '/usercenter'
        }
        else {
          console.log("登录失败")
        }
      })
      this.$store.commit('upDate', {email: this.loginForm.email});
      this.$store.commit('upDate', {password: this.loginForm.password});
      this.isShow = true
      this.$emit('isShow', this.isShow)
    },
    findPassword(){
      console.log("11111")
      this.$router.push("/find-password")
    }
  },
}
</script>

<style scoped>
input{
  outline: none;
  width: 100%;
  border: none;
  background: none;
  height: 40px;
  font-size: 18px;
}
#loginwindowPhone{
  display: none;
}
.text{
  font-size: 24px;
}
.formBox{
  position: relative;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
}
.errorMessage{
  position: absolute;
  bottom: -26px;
  right: 20px;
}
.login{
  width: 100%;
}
.loginline{
  position: relative;
  height: 40px;
  display: flex;
}
.checkbox{
  height: 26px;
  width: 26px;
  margin: 0 20px;
}
.loginline a{
  position: absolute;
  right: 20px;
}
a{
  cursor: pointer;
}
@media screen and (max-width: 1050px){
  #loginwindow{
    display: none;
  }
  #loginwindowPhone{
    display: block;
    padding: 0 40px;
  }
  .loginline span{
    display: none;
  }
  .checkbox{
    display: none;
  }
}
</style>
