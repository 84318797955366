<template>
  <div id='loading' loading: isLoading>
    
  </div>

</template>

<script>


export default {
  name:'Loading',
  components: {

  },
  data(){
    return{
      
    }
  },
  
}
</script>

<style scoped>


#loading{
  width: 100vw;
  height: calc(100vh + 50px);
  background: rgba(0, 0, 0, .5);
  position: absolute;
  display: flex;            
  display: -webkit-flex;            
  justify-content: center;            
  align-items: center; 
  z-index: 100;
  transition: 2s;
  left: 0;
  top: -50px;
}

</style>

